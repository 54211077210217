import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { required, email, length } from 'redux-form-validators';
import { isValidNumber } from 'libphonenumber-js';

import { Agreements, ReduxTextField, ReduxSelectField, PhoneField, DefaultButton } from 'common/components';
import { withTheme } from 'common/styling/theme';
import TelegramAuthWidget from '../../../_components/TelegramAuthWidget';

import { loginWithTelegramFromReg, registration, togglePartnerCodeAction, togglePromoCodeAction } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const validatePhoneNumber = phoneNumber => {
  if (!isValidNumber(phoneNumber)) {
    return <FormattedMessage id="validationErrorWrongPhone" />;
  }
};

const validate = values => {
  const errors = {};

  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).+$/;
  if (!passwordRegex.test(values.password)) {
    errors.password = <FormattedMessage id="form.errors.passwordValidate" />;
  }

  if (values.passwordRepeat !== values.password) {
    errors.passwordRepeat = <FormattedMessage id="form.errors.passwordRepeat" />; // eslint-disable-line
  }

  if (!/^\d+$/.test(values.partnerCode)) {
    errors.partnerCode = <FormattedMessage id="form.errors.partnerCode" />; // eslint-disable-line
  }

  return errors;
};

function RegistrationForm({
  partnership,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  error,
  partnerCodeIsOpen,
  promoCodeIsOpen,
  disclamer,
  requirePartnerCode,
  togglePartnerCode,
  togglePromoCode,
  theme,
  registrationSettings,
  customRegistrationFormOnebid,
  initialValues,
  dispatch,
  isTelegramAuth,
  telegramData,
  loginWithTelegramFromReg,
  countries,
  countriesAreLoaded,
  personalIdInKycForm,
}) {
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  const showPassword = () => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text');
    } else {
      setPasswordFieldType('password');
    }
  };

  useEffect(() => {
    if (!partnerCodeIsOpen && (initialValues.partnerCode || requirePartnerCode)) {
      togglePartnerCode();
    }
  }, []);

  const submit = handleSubmit(values => {
    const element = document.getElementById('registration-page-partner-code');
    let isPartnerCodeCustomlyHidden = false;
    if (element) {
      const style = window.getComputedStyle(element);
      isPartnerCodeCustomlyHidden = style.getPropertyValue('display') === 'none';
    }
    const { partnerCode, ...data } = values;
    let sendData = partnerCodeIsOpen ? { ...values, isPartnerCodeCustomlyHidden } : data;

    if (isTelegramAuth && telegramData) sendData = { ...sendData, ...telegramData };

    if (customRegistrationFormOnebid && !promoCodeIsOpen && sendData.promoCode) {
      delete sendData.promoCode;
    }

    return registration(sendData, dispatch);
  });

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const handleTelegramAuth = data => loginWithTelegramFromReg(data);

  const countriesOptions = countries.map(option => ({
    value: option.gid,
    label: option.name,
  }));

  return (
    <form id="main-registration-form" className="RegistrationForm" onSubmit={submit}>
      {/* temp for tixee */}
      {window.location.hostname !== 'my.tixee.com' && disclamer?.enabled && disclamer?.subject && (
        <div className="disclamer__text">{disclamer.subject}</div>
      )}
      {!customRegistrationFormOnebid && (
        <div className="RegistrationForm__inputs-inner">
          <Field
            component={ReduxTextField}
            type="text"
            name="firstName"
            textId="justFirstName"
            validate={[required()]}
            disabled={submitting}
          />
          <Field
            component={ReduxTextField}
            type="text"
            name="lastName"
            textId="justLastName"
            validate={[required()]}
            disabled={submitting}
          />
        </div>
      )}
      <Field
        component={ReduxTextField}
        type="email"
        name="email"
        textId="authEmail"
        validate={[required(), email()]}
        disabled={submitting}
      />
      {!customRegistrationFormOnebid && (
        <Field
          component={PhoneField}
          name="phone"
          textId="justPhone"
          validate={[required(), validatePhoneNumber]}
          disabled={submitting}
        />
      )}

      {customRegistrationFormOnebid && (
        <Field
          className="RegistrationForm__select-country"
          component={ReduxSelectField}
          searchable
          name="countryGid"
          textId="justCountryResidence"
          options={countriesOptions}
          disabled={submitting || !countriesAreLoaded}
          validate={[required()]}
        />
      )}
      {personalIdInKycForm && !customRegistrationFormOnebid && (
        <>
          <Field
            className="RegistrationForm__select-country"
            component={ReduxSelectField}
            searchable
            name="countryGid"
            textId="justCountryResidence"
            options={countriesOptions}
            disabled={submitting || !countriesAreLoaded}
            validate={[required()]}
          />

          <Field
            component={ReduxTextField}
            type="text"
            name="personalId"
            textId="kycPassportNumber"
            disabled={submitting}
            validate={[required()]}
          />
        </>
      )}
      {!isTelegramAuth && (
        <div className="RegistrationForm__inputs-inner">
          <Field
            component={ReduxTextField}
            type={passwordFieldType}
            name="password"
            textId="authPassword"
            validate={[required(), length({ min: 6 })]}
            disabled={submitting}
            action={showPassword}
            actionTooltipId={passwordFieldType === 'password' ? 'showPassword' : 'hidePassword'}
            actionClassName={passwordFieldType === 'password' ? 'show' : 'hide'}
          />
          <Field
            component={ReduxTextField}
            type="password"
            name="passwordRepeat"
            textId="authPasswordRepeat"
            validate={[required()]}
            disabled={submitting}
          />
        </div>
      )}
      {partnership.enabled && partnerCodeIsOpen && (
        <Field
          id="registration-page-partner-code"
          component={ReduxTextField}
          type="text"
          name="partnerCode"
          textId="authPartnerCodeField"
          validate={[required()]}
          disabled={submitting}
        />
      )}
      {promoCodeIsOpen && customRegistrationFormOnebid && (
        <Field
          id="registration-page-promo-code"
          component={ReduxTextField}
          type="text"
          name="promoCode"
          textId="authPromoCodeField"
          validate={[required()]}
          disabled={submitting}
        />
      )}
      {partnership.enabled && !requirePartnerCode && (
        <div id="registration-page-partner-code-inner" className="PartnerCode__inner">
          <FormattedMessage id={partnerCodeIsOpen ? 'authNoPartnerCode' : 'authPartnerCode'}>
            {txt => (
              <button type="button" onClick={togglePartnerCode}>
                {txt}
              </button>
            )}
          </FormattedMessage>
        </div>
      )}
      {customRegistrationFormOnebid && (
        <div id="registration-page-promo-code-inner" className="PartnerCode__inner">
          <FormattedMessage id={promoCodeIsOpen ? 'authNoPromoCode' : 'authPromoCode'}>
            {txt => (
              <button type="button" onClick={togglePromoCode}>
                {txt}
              </button>
            )}
          </FormattedMessage>
        </div>
      )}
      <Agreements agreements={registrationSettings.agreements} submitting={submitting} />
      {/* temp for tixie */}
      {window.location.hostname === 'my.tixee.com' && disclamer?.enabled && disclamer?.subject && (
        <div className="disclamer__text disclamer__text--noMG">{disclamer.subject}</div>
      )}
      <DefaultButton
        id="registration-page-registration-button"
        textId="authDoRegister"
        type="submit"
        disabled={invalid || pristine || submitting}
        loading={submitting}
        filled
      />
      <TelegramAuthWidget handleTelegramAuth={handleTelegramAuth} />
      {error && <strong className="formError">{error}</strong>}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
}

RegistrationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  theme: PropTypes.object,
  partnership: PropTypes.object.isRequired,
  partnerCodeIsOpen: PropTypes.bool,
  promoCodeIsOpen: PropTypes.bool,
  disclamer: PropTypes.object,
  requirePartnerCode: PropTypes.bool.isRequired,
  togglePartnerCode: PropTypes.func.isRequired,
  togglePromoCode: PropTypes.func.isRequired,
  registrationSettings: PropTypes.object.isRequired,
  customRegistrationFormOnebid: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  countries: PropTypes.array,
  countriesAreLoaded: PropTypes.bool.isRequired,
  personalIdInKycForm: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    partnerCode: PropTypes.string,
  }),
  isTelegramAuth: PropTypes.bool.isRequired,
  telegramData: PropTypes.object.isRequired,
  loginWithTelegramFromReg: PropTypes.func.isRequired,
};

RegistrationForm.defaultProps = {
  error: '',
  theme: {},
  disclamer: {},
  partnerCodeIsOpen: false,
  promoCodeIsOpen: false,
  initialValues: {},
  countries: [],
};

export default compose(
  withTheme(),
  withRouter,
  connect(
    state => ({
      registrationSettings: state.interfaceConfig.registration,
      partnership: state.interfaceConfig.partnership,
      partnerCodeIsOpen: state.auth.registration.partnerCodeIsOpen,
      promoCodeIsOpen: state.auth.registration.promoCodeIsOpen,
      requirePartnerCode: state.interfaceConfig.requirePartnerCode,
      disclamer: state.interfaceConfig.disclamer,
      personalIdInKycForm: state.interfaceConfig.features.includes('personalIdInKycForm'),
      customRegistrationFormOnebid: state.interfaceConfig.features.includes('customRegistrationFormOnebid'),
    }),
    dispatch => ({
      togglePartnerCode: () => dispatch(togglePartnerCodeAction()),
      togglePromoCode: () => dispatch(togglePromoCodeAction()),
      loginWithTelegramFromReg: data => dispatch(loginWithTelegramFromReg.request(data)),
    })
  ),
  reduxForm({
    form: 'registration',
    validate,
    onSubmitSuccess: (
      result,
      dispatch,
      { history, isEmailConfirmationEnabled, displayThankBlock, displayTfaModal, isTfaRequired }
    ) => {
      // Этот элемент указывает, что регистрация прошла успешно
      const divElement = document.createElement('div');

      divElement.id = 'div-that-indicates-successful-registration';

      divElement.style.width = '1px';
      divElement.style.height = '1px';
      divElement.style.display = 'none !important';

      document.body.appendChild(divElement);

      if (isTfaRequired) {
        displayTfaModal();
      }

      if (isEmailConfirmationEnabled) {
        displayThankBlock();
      }

      if (!isTfaRequired && !isEmailConfirmationEnabled) {
        history.push('/');
      }
    },
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })
)(RegistrationForm);
export { RegistrationForm };
