import React from 'react';

import { VerificationPanel, SecurityPanelContent, TwoStepSecurityPanel } from '../../_components';

const ChangePasswordPage = () => (
  <VerificationPanel captionId="justSecurity" warningMessageTextId="justSecurity">
    <SecurityPanelContent />
    <TwoStepSecurityPanel />
  </VerificationPanel>
);

export default ChangePasswordPage;
export { ChangePasswordPage };
