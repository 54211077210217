import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { SwitchField, RadioButton, DefaultButton, BasicModal } from 'common/components';

import ChangingAuthMethod from '../ChangingAuthMethod';

import { staticStyles, getDynamicStyles } from './style';

const TwoStepSecurityPanel = ({ theme, isEnabled }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [selectedMethod, setSelectedMethod] = useState(null);
  const [isOpened, setIsOpened] = useState(false);

  const handleMethodChange = method => {
    setSelectedMethod(method);
  };

  const handleModalToggle = () => {
    setIsOpened(open => !open);
  };

  return (
    <div className="TwoStepSecurityPanelForm__wrapper">
      <form className="TwoStepSecurityPanelForm">
        <div className="TwoStepSecurityPanelForm__enable-block">
          <FormattedMessage id="authTwoStepAuthentication">
            {txt => <h2 className="TwoStepSecurityPanelForm__title">{txt}</h2>}
          </FormattedMessage>
          <div className="TwoStepSecurityPanelForm__enable-block__field">
            <Field component={SwitchField} name="isEnabled" type="checkbox" />
            <FormattedMessage id="authEnableTwoFactorAuth" />
          </div>
        </div>
        <div className="TwoStepSecurityPanelForm__choose-block">
          <FormattedMessage id="authChooseAuthMethod">
            {txt => <h2 className="TwoStepSecurityPanelForm__title">{txt}</h2>}
          </FormattedMessage>
          <div className="TwoStepSecurityPanelForm__choose-block__options">
            <div className="TwoStepSecurityPanelForm__choose-block__option">
              <RadioButton
                textId="authAuthenticatorApp"
                checked={selectedMethod === 'google'}
                onChange={() => handleMethodChange('google')}
              />
              <div className="TwoStepSecurityPanelForm__choose-block__option__caption">
                <FormattedMessage
                  id="authGetCodeFromGoogle"
                  values={{
                    link: (
                      <Link className="TwoStepSecurityPanelForm__choose-block__link " to="/">
                        <FormattedMessage id="authGoogleAuthenticator" />
                      </Link>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="TwoStepSecurityPanelForm__choose-block__option">
              <RadioButton
                textId="authTwoStepEmail"
                checked={selectedMethod === 'email'}
                onChange={() => handleMethodChange('email')}
              />
              <div className="TwoStepSecurityPanelForm__choose-block__option__caption">
                <FormattedMessage id="authRecieveEmailUniqueCode" />
              </div>
            </div>
          </div>
        </div>
        <DefaultButton
          textId="justChange"
          filled
          disabled={!isEnabled || !selectedMethod}
          onClick={handleModalToggle}
        />
      </form>
      <BasicModal
        modalClassName="ChangingAuthMethodModal"
        captionId="authChangingAuthMethod"
        isOpen={isOpened}
        onRequestClose={handleModalToggle}>
        <ChangingAuthMethod selectedMethod={selectedMethod} onClose={handleModalToggle} />
      </BasicModal>
      <style jsx global>
        {dynamicStyles}
      </style>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

TwoStepSecurityPanel.propTypes = {
  theme: PropTypes.object,
  isEnabled: PropTypes.bool,
};

TwoStepSecurityPanel.defaultProps = {
  isEnabled: false,
  theme: {},
};

const selector = formValueSelector('TwoStepSecurityPanelForm');

export default compose(
  withTheme(),
  connect(
    state => ({
      isEnabled: selector(state, 'isEnabled'),
    }),
    {}
  ),
  reduxForm({
    form: 'TwoStepSecurityPanelForm',
    // onSubmitSuccess: (result, dispatch, props) => {},
  })
)(TwoStepSecurityPanel);
