import * as ActionTypes from './actions';

export const initialState = {
  canMoveToVerifyStep: false,
  tfaContent: {},
};

// Добавить в setTfaType SUCCESS дату (qr код)

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.setTfaType.REQUEST:
      return { ...state, canMoveToVerifyStep: false };

    case ActionTypes.setTfaType.SUCCESS:
      return { ...state, canMoveToVerifyStep: true };

    case ActionTypes.setTfaType.FAILURE:
      return { ...state, canMoveToVerifyStep: false };

    default:
      return state;
  }
};

export default reducer;
