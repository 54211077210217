import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DefaultButton, ReduxTextField } from 'common/components';
import { withTheme } from 'common/styling/theme';

import { staticStyles, getDynamicStyles } from './style';

const TwoStepEmailForm = ({
  onClose,
  onSubmit,
  onGetCode,
  theme,
  handleSubmit,
  dispatch,
  submitting,
  error,
  canMoveToVerifyStep,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [step, setStep] = useState('getCode');
  const [resendCodeVisible, setResendCodeVisible] = useState(false);
  const [resendTime, setResendTime] = useState(30);
  const timerRef = useRef(null);

  const handleTimerReset = () => {
    clearInterval(timerRef.current);
    timerRef.current = null;
  };

  const handleTimerStart = () => {
    if (timerRef.current) return;

    setResendTime(30);

    timerRef.current = setInterval(() => {
      setResendTime(prevTime => {
        if (prevTime === 0) {
          handleTimerReset();

          return 0;
        }

        return prevTime - 1;
      });
    }, 1000);
  };

  const submit = handleSubmit(values => {
    console.log({ values });

    setResendCodeVisible(true);
    handleTimerStart();

    return onSubmit(values, dispatch);
  });

  const handleResend = () => {
    handleTimerStart();
  };

  const handleGetCode = () => {
    dispatch(onGetCode());
  };

  useEffect(() => {
    if (canMoveToVerifyStep) {
      setStep('verify');
    }
  }, [canMoveToVerifyStep]);

  useEffect(() => handleTimerReset(), []);

  return (
    <div className="TwoStepEmailForm__wrapper">
      <form className="TwoStepEmailForm" onSubmit={submit}>
        {step === 'getCode' && (
          <div className="TwoStepEmailForm__get-code-block">
            <FormattedMessage id="authEmailWillBeSent">
              {txt => <span className="TwoStepEmailForm__get-code-block__caption">{txt}.</span>}
            </FormattedMessage>
            <DefaultButton textId="authGetCode" filled onClick={handleGetCode} />
          </div>
        )}
        {step === 'verify' && (
          <div className="TwoStepEmailForm__message-sent-block">
            <div className="TwoStepEmailForm__message-sent-block__captions">
              <FormattedMessage id="authMessageWasSent">
                {txt => <p className="TwoStepEmailForm__message-was-sent">{txt}.</p>}
              </FormattedMessage>
              <FormattedMessage id="authPleaseEnterVerificationCode">
                {txt => <p className="TwoStepEmailForm__message-enter-code">{txt}:</p>}
              </FormattedMessage>
            </div>
            <div className="TwoStepEmailForm__code-field">
              <Field component={ReduxTextField} name="otp" textId="authCode" disabled={submitting} />
              <DefaultButton
                type="submit"
                textId="justVerify"
                filled
                className="TwoStepEmailForm__submit-button"
                disabled={submitting}
              />
            </div>
            {resendCodeVisible && (
              <div className="TwoStepEmailForm__resendCode">
                <FormattedMessage
                  id="authResentCodeIn"
                  values={{ time: resendTime < 10 ? `0${resendTime}` : resendTime }}
                />
                <FormattedMessage id="authResendCode">
                  {txt => (
                    <button
                      className={classNames('TwoStepEmailForm__resendCode-btn', {
                        'TwoStepEmailForm__resendCode-btn-disabled': resendTime !== 0,
                      })}
                      disabled={resendTime !== 0}
                      onClick={handleResend}>
                      {txt}
                    </button>
                  )}
                </FormattedMessage>
              </div>
            )}
            {error && <strong className="formError">{error}</strong>}
          </div>
        )}
      </form>
      <DefaultButton textId="justCancel" className="TwoStepEmailForm__cancel-button" onClick={onClose} />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

TwoStepEmailForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onGetCode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  canMoveToVerifyStep: PropTypes.bool,
  error: PropTypes.string,
  theme: PropTypes.object,
};

TwoStepEmailForm.defaultProps = {
  canMoveToVerifyStep: false,
  error: '',
  theme: {},
};

export default compose(
  withTheme(),
  connect(state => ({
    canMoveToVerifyStep: state.auth.tfa.canMoveToVerifyStep,
  })),
  reduxForm({
    form: 'TwoStepEmailForm',
    onSubmitSuccess: (result, dispatch, { onClose, onSuccess }) => {
      onClose();
      onSuccess?.('authCodesWillBeSentEmail');
    },
  })
)(TwoStepEmailForm);
