import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { DefaultButton, ReduxTextField } from 'common/components';

import { getJWTToken, resetAuth } from '../../_redux';
import ResendOTPButton from '../ResendOTPButton';

import { staticStyles } from './style';

export const PhoneCodeForm = ({ rememberUser, handleSubmit, pristine, submitting, dispatch, error }) => {
  const submit = handleSubmit(formValues => getJWTToken({ otp: formValues.code, rememberMe: rememberUser }, dispatch));
  return (
    <form className="PhoneCodeForm" onSubmit={submit}>
      <div className="PhoneCodeForm__input">
        <Field component={ReduxTextField} type="text" name="code" textId="authCode" disabled={submitting} />
      </div>

      <div className="PhoneCodeForm__buttons">
        <DefaultButton
          textId="authConfirm"
          type="submit"
          disabled={pristine || submitting}
          loading={submitting}
          filled
        />
        <ResendOTPButton />
      </div>

      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
    </form>
  );
};

PhoneCodeForm.propTypes = {
  rememberUser: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

PhoneCodeForm.defaultProps = {
  error: '',
};

export default withRouter(
  reduxForm({
    form: 'getJWTToken',
    onSubmitSuccess: (result, dispatch, { history }) => {
      dispatch(resetAuth(''));
      history.push('/');
    },
  })(PhoneCodeForm)
);
