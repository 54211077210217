import css from 'styled-jsx/css';

const staticStyles = css.global`
  .TwoStepEmailForm {
    padding: 32px 24px;
    background: #fafafa;
    border-radius: 12px;

    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__get-code-block {
      display: flex;
      gap: 16px;

      &__caption {
        flex: 2;
      }
    }

    &__message-sent-block {
      &__captions {
        text-align: center;
      }
    }

    &__message-was-sent {
      margin-bottom: 32px;
      font-size: 16px;
    }

    &__message-enter-code {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 16px;
    }

    &__code-field {
      display: flex;
      gap: 16px;
    }

    .ReduxTextField {
      width: 100%;
    }

    .BaseTextField__inp {
      background: #fff;
    }

    .BaseTextField__label {
      z-index: 1111;
    }

    &__submit-button {
      height: 53px;
    }

    &__resendCode {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-btn {
        text-decoration: underline;
        font-size: 16px;
        font-weight: 500;

        &-disabled {
          opacity: 0.5;
        }
      }
    }

    &__cancel-button {
      margin-top: 24px;
      align-self: flex-end;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .TwoStepEmailForm {
      &__header {
        &__link {
          color: ${colors.primary.hex};
        }
      }

      &__resendCode {
        &-btn {
          color: ${colors.primary.hex};
        }
      }

      &__cancel-button {
        color: ${colors.black.hex} !important;
        border-color: #f0f0f0 !important;

        &:hover {
          background: #f0f0f0 !important;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
