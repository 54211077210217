import css from 'styled-jsx/css';
import { gradient, rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .ChangingAuthMethod {
    &__steps {
      margin-bottom: 24px;
      display: grid;
      grid-template-columns: 2fr 1fr 3fr;
      gap: 16px;

      @include respond-to(sm) {
        gap: 8px;
      }

      @include respond-to(xs) {
        grid-template-columns: repeat(2, 1fr);
      }

      &__delimeter {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        height: 1px;
        border-top: 1px dashed;

        @include respond-to(xs) {
          display: none;
        }
      }
    }

    &__step {
      display: flex;
      gap: 16px;
      align-items: center;

      @include respond-to(xs) {
        gap: 4px;
      }

      &__number {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 500;

        @include respond-to(xs) {
          width: 36px;
          height: 36px;
          font-size: 18px;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .ChangingAuthMethod {
      &__steps {
        &__delimeter {
          border-color: ${rgba([colors.black.rgb, 0.5])};
        }
      }

      &__step {
        &__number {
          color: ${colors.primary.hex};
          background: ${colors.white.hex};
          border: 1px solid ${colors.primary.hex};
        }

        &__active &__number {
          color: ${colors.white.hex};
          background: ${gradient(53.07, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
          border: none;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
