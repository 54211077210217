import React from 'react';

const SuccessIcon = () => (
  <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="64" height="64" rx="32" fill="#39B841" />
    <path
      d="M43.7469 24.6084C44.0844 24.9406 44.0844 25.487 43.7469 25.8191L29.1763 40.3897C28.8441 40.7272 28.2978 40.7272 27.9656 40.3897L20.251 32.6758C19.9163 32.3437 19.9163 31.7973 20.251 31.4652C20.5855 31.1277 21.1282 31.1277 21.463 31.4652L28.571 38.5737L42.5362 24.6084C42.8684 24.2731 43.4148 24.2731 43.7469 24.6084Z"
      fill="white"
    />
  </svg>
);

export default SuccessIcon;
