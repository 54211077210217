import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { TwoStepEmailForm, TwoStepGoogleForm } from 'auth/twoStepAuth/_components';

import { staticStyles, getDynamicStyles } from './style';

const ChangingAuthMethod = ({ onClose, selectedMethod, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [step] = useState(1);

  return (
    <div className="ChangingAuthMethod">
      <div className="ChangingAuthMethod__steps">
        <div className={classNames('ChangingAuthMethod__step', { ChangingAuthMethod__step__active: step === 1 })}>
          <div className="ChangingAuthMethod__step__number">1</div>
          <FormattedMessage id="authVerification" />
        </div>
        <div className="ChangingAuthMethod__steps__delimeter" />
        <div className={classNames('ChangingAuthMethod__step', { ChangingAuthMethod__step__active: step === 2 })}>
          <div className="ChangingAuthMethod__step__number">2</div>
          <FormattedMessage id="authMethodChanging" />
        </div>
      </div>
      {selectedMethod === 'email' && (
        <>
          {step === 1 && <TwoStepEmailForm onClose={onClose} />}
          {step === 2 && <TwoStepGoogleForm onClose={onClose} />}
        </>
      )}
      {selectedMethod === 'google' && (
        <>
          {step === 1 && <TwoStepGoogleForm onClose={onClose} />}
          {step === 2 && <TwoStepEmailForm onClose={onClose} />}
        </>
      )}
      <style jsx>{dynamicStyles}</style>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

ChangingAuthMethod.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedMethod: PropTypes.string.isRequired,
  theme: PropTypes.object,
};

ChangingAuthMethod.defaultProps = {
  theme: {},
};

export default compose(withTheme(), connect(null))(ChangingAuthMethod);
