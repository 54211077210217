import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { SuccessIcon } from 'common/icons';
import { withTheme } from 'common/styling/theme';
import { DefaultButton } from 'common/components';
import { staticStyles, getDynamicStyles } from './style';

const TwoStepSuccess = ({ captionTextId, successTextId, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="TwoStepSuccess__wrapper">
      <div className="TwoStepSuccess">
        <div className="TwoStepSuccess__icon">
          <SuccessIcon />
        </div>
        <FormattedMessage id={successTextId}>{txt => <p className="TwoStepSuccess__title">{txt}</p>}</FormattedMessage>
        <FormattedMessage id={captionTextId}>
          {txt => <p className="TwoStepSuccess__caption">{txt}</p>}
        </FormattedMessage>
      </div>
      <DefaultButton className="TwoStepSuccess__button" textId="justDone" filled />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

TwoStepSuccess.propTypes = {
  successTextId: PropTypes.string.isRequired,
  captionTextId: PropTypes.string.isRequired,
  theme: PropTypes.object,
};

TwoStepSuccess.defaultProps = {
  theme: {},
};

export default withTheme()(TwoStepSuccess);
