import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const PanelTabs = ({
  activeTabSlug: activeTabProps,
  history,
  location,
  theme,
  children,
  noTranslate,
  firstElemRef,
}) => {
  const dynamicStyles = useMemo(() => (Object.keys(theme).length ? getDynamicStyles(theme) : ` `), [theme]);
  const allowedTabs = useMemo(() => React.Children.toArray(children).filter(elem => elem), [children]);
  const query = useMemo(() => queryString.parse(location.search), [location.search]);
  const [activeTabSlug, setActiveTabSlug] = useState(activeTabProps);
  const activeTab = useMemo(
    () => allowedTabs.find(elem => elem.props.slug === activeTabSlug),
    [allowedTabs, activeTabSlug]
  );

  useEffect(() => {
    if (activeTabSlug !== activeTabProps) {
      setActiveTabSlug(activeTabProps);
    }
  }, [activeTabSlug, setActiveTabSlug, activeTabProps]);

  const handleChange = slug => {
    history.push({
      ...location,
      search: queryString.stringify({ ...query, tab: slug }),
    });
    setActiveTabSlug(slug);
  };

  return (
    <div className="PanelTabs">
      {allowedTabs.length > 1 && (
        <ul>
          {allowedTabs.map((elem, index) => (
            <li
              className={classNames('PanelTab', {
                PanelTab__active: elem.props.slug === activeTabSlug,
                PanelTab__disabled: elem.props.disabled,
              })}
              key={index}>
              <button
                ref={index === 0 ? firstElemRef : null}
                className="PanelTabs__button"
                type="button"
                onClick={() => (!elem.props.disabled ? handleChange(elem.props.slug) : {})}>
                <Link to={`${location.pathname}?tab=${elem.props.slug}`}>
                  {noTranslate ? (
                    <span>{elem.props.textId}</span>
                  ) : (
                    <FormattedMessage id={elem.props.textId}>{txt => txt}</FormattedMessage>
                  )}
                </Link>
              </button>
            </li>
          ))}
        </ul>
      )}
      <div className="PanelTabs__content">{activeTab}</div>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

PanelTabs.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  activeTabSlug: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  children: PropTypes.node.isRequired,

  theme: PropTypes.object,
};

PanelTabs.defaultProps = {
  theme: {},
};

export default withTheme()(withRouter(PanelTabs));
export { PanelTabs };
