import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css.global`
  .TwoStepModal {
    max-width: 620px;

    h2 {
      font-weight: 500;
    }

    .PanelTabs__button {
      font-size: 16px;
    }

    &__caption {
      margin-bottom: 16px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .TwoStepModal {
      &__caption {
        color: ${rgba([colors.black.rgb, 0.75])};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
