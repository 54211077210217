import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css.global`
  .TwoStepSuccess {
    padding: 32px 24px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 12px;
    background: #fafafa;

    &__wrapper {
      display: flex;
      gap: 24px;
      flex-direction: column;
    }

    &__title {
      font-size: 18px;
      font-weight: 500;
    }

    &__button {
      align-self: flex-end;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .TwoStepSuccess {
      &__title {
        color: ${colors.success.hex};
      }

      &__caption {
        color: ${rgba([colors.black.rgb, 0.75])};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
