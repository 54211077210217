import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DefaultButton, ReduxTextField } from 'common/components';
import { withTheme } from 'common/styling/theme';

import { staticStyles, getDynamicStyles } from './style';

const TwoStepGoogleForm = ({ onClose, onSubmit, tfaContent, handleSubmit, dispatch, submitting, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const submit = handleSubmit(values => {
    console.log({ values });

    return onSubmit(values, dispatch);
  });

  // картинка и код
  console.log({ tfaContent });

  return (
    <div className="TwoStepGoogleForm__wrapper">
      <form className="TwoStepGoogleForm" onSubmit={submit}>
        <div className="TwoStepGoogleForm__header">
          <FormattedMessage
            id="authYouWillNeedGoogleAuthenticator"
            values={{
              link: (
                <Link className="TwoStepGoogleForm__header__link" to="/">
                  <FormattedMessage id="authGoogleAuthenticator" />
                </Link>
              ),
            }}
          />
        </div>
        <div className="TwoStepGoogleForm__field">
          <FormattedMessage id="authEnterAuthenticationCode">{txt => <span>{txt}:</span>}</FormattedMessage>
          <div className="TwoStepGoogleForm__field__code">
            <Field component={ReduxTextField} name="code" textId="authCode" disabled={submitting} />
            <DefaultButton
              textId="justVerify"
              filled
              className="TwoStepGoogleForm__submit-button"
              disabled={submitting}
            />
          </div>
        </div>
      </form>
      <DefaultButton textId="justCancel" className="TwoStepGoogleForm__cancel-button" onClick={onClose} />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

TwoStepGoogleForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  tfaContent: PropTypes.object,
  theme: PropTypes.object,
};

TwoStepGoogleForm.defaultProps = {
  tfaContent: {},
  theme: {},
};

export default compose(
  withTheme(),
  connect(state => ({
    tfaContent: state.auth.tfa.tfaContent,
  })),
  reduxForm({
    form: 'TwoStepGoogleForm',
    onSubmitSuccess: (result, dispatch, { onClose, onSuccess }) => {
      onClose();
      onSuccess?.('authCodesWillBeUsedGoogle');
    },
  })
)(TwoStepGoogleForm);
